<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-[99999]"
    @click.self="emit('close')"
  >
    <div
      class="relative bg-white rounded-lg shadow-xl text-left w-full max-w-md max-h-full overflow-y-auto overflow-x-clip mx-2"
    >
      <button class="absolute top-2 right-2 mt-2 mr-2 text-gray-700 hover:text-gray-900" @click="emit('close')">
        <Icon name="tabler:x" class="w-6 h-6" />
      </button>
      <slot />
      <FormsLoginForm :showTitle="false" :showBorder="false" v-show="showLoginForm" :login-redirect="loginRedirect" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  showLoginForm: {
    type: Boolean,
    default: false,
  },
  loginRedirect: {
    type: String,
    default: 'account',
  },
});

const { t } = useI18n();
const emit = defineEmits(['close']);

onUnmounted(() => {
  emit('close');
});
</script>

<style scoped></style>
